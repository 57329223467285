html {
  --color-primary: #4ab819;
  --color-skeleton: #e8e8e8;
  --bg-gradient: linear-gradient(
    to right,
    #f0f0f0 8%,
    #e0e0e0 18%,
    #f0f0f0 33%
  );
  --content-description-font-size: 1rem;
  --content-title-font-size: 1.5rem;
  --size-l: 1.5rem;
  --size-m: 2.5rem;
  --size-spacing-s: 0.25rem;
  --size-font-m: 0.875rem;
  --color-black: #000000;
  --color-white: #ffffff;
  --size-border-width-s: 0.063rem;
  --color-border-input: #00000040;
  --color-gray-300: #00000040;
  --size-spacing-xs: 0.5rem;
  --color-error: #fa1f4b;
}
